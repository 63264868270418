import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    Fragment,
} from "react"
import axios from "axios";
import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo";
import PageBanner from "../components/common/PageBanner";
import TextBox from "../components/inputs/Textbox";
import TextArea from "../components/inputs/TextArea";
import SelectBox from "../components/inputs/SelectBox";
import FileInput from "../components/inputs/FileInput";
import CheckBox from "../components/inputs/CheckBox";
import { Link } from 'gatsby'

import Loading from "../components/common/Loading";
import SuccesMessage from "../components/common/SuccesMessage";
import ErrorMessage from "../components/common/ErrorMessage";

const securityLevel = [
    "Low",
    "Medium",
    "High",
    "Critical",
]

const remainAnonymous = [
    "Yes",
    "No",
]

const ReportSecurityIssue = () => {
    const [txtValues, setTxtValues] = useState({
        name: "",
        email: "",
        title: "",
        details: "",
        otherInfo: "",
        screenshot: null,
        consent: 0,
    });
    const [selectValues, setSelectValues] = useState({
        securityLevel:"",
        remainAnonymous:"",
    })

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(-1);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        const checkbox_value = checked ? 1 : 0
        setTxtValues({ ...txtValues, [name]: type === "checkbox" ? checkbox_value : value });
    };

    const handleSelectChange = e => {
        setSelectValues({ ...selectValues, [e.name]: e.id })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        setSuccess(-1);

        const formData = new FormData();
        formData.append("name", txtValues.name);
        formData.append("email", txtValues.email);
        formData.append("title", txtValues.title);
        formData.append("details", txtValues.details);
        formData.append("other_info", txtValues.otherInfo);
        formData.append("screenshot", document.querySelector('input[type="file"]').files[0]);
        formData.append("consent", txtValues.consent);
        formData.append("security_level", selectValues.securityLevel);
        formData.append("remain_anonymous", selectValues.remainAnonymous === "Yes" ? 1 : 0);
        
        try {
            const res = await axios.post(
                `${process.env.GATSBY_API_URL}/vulnerability-form`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            if (res.data.status) {
                setSuccess(1);
                setErrors({});
                setTxtValues({
                    name: "",
                    email: "",
                    title: "",
                    details: "",
                    otherInfo: "",
                    screenshot: null,
                    consent: 0,
                });
                document.querySelector('input[type="file"]').value = null
                setSelectValues({
                    securityLevel:"",
                    remainAnonymous:"",
                })
              } else {
                setSuccess(0);
                setErrors(res.data.errors);
              }
              setSending(false);
        } catch (error) {
            setSuccess(0);
            setSending(false);
        }

    }
    
    const handleClose = () => {
        setSuccess(-1);
        setErrors({});
    };
    
    return (
        <>
            {sending && <Loading />}
            {success === 1 && <SuccesMessage handleClose={handleClose} />}
            {errors.send_mail && <ErrorMessage handleClose={handleClose} />}
            <Layout>
                
                <Seo
                    title='Security Vulnerability Reporting | VIRSAT'
                    description='At VIRSAT LLC, we take security seriously. We are committed to maintaining the highest level of safety for our systems and data.'
                />
                <PageBanner
                    title='Report a Security Issue'
                />
                <section className="main-container">
                    <div className="row less-margin top-pad fd-col bottom-pad">
                        <div className="column show-overflow">
                            <h1 className="main-title">Security Vulnerability Reporting</h1>
                            <p>At VIRSAT LLC, we take security seriously. We are committed to maintaining the highest level of safety for our systems and data. If you discover any potential vulnerabilities or security issues in our software or services, we encourage you to report them responsibly.</p>
                            
                            <h2 style={{marginTop: '20px'}}>How to Report a Vulnerability:</h2>
                            
                            <ul style={{ listStyleType: 'disc', marginLeft: '30px', marginTop: '10px'}}>
                                <li>Please provide a detailed description of the vulnerability, including steps to reproduce the issue if possible.</li>
                                <li>Include information about the environment where the vulnerability was found (e.g., browser version, operating system).</li>
                                <li>For privacy and security reasons, please do not publicly disclose the vulnerability until it has been resolved.</li>
                            </ul>
                            <p>Once we receive your report, our team will review the issue and work with you to resolve it as quickly as possible. We greatly appreciate your efforts to help us improve the security of our services.</p>

                            <form style={{marginTop: '30px'}} action='' method='post' onSubmit={handleSubmit}>
                                <div className='label-container'>
                                    <label className='' htmlFor='name'>
                                    Name*
                                    </label>
                                    <TextBox
                                    type='text'
                                    id='name'
                                    name='name'
                                    placeholder='Eg. John Doe'
                                    txtBoxClass='regular-text'
                                    onChange={handleInputChange}
                                    value={txtValues.name}
                                    required
                                    error={errors.name}
                                    />
                                    {errors.name && (
                                    <div className='field-error'>{errors.name[0]}</div>
                                    )}
                                </div>
                                <div className='label-container'>
                                    <label className='' htmlFor='email'>
                                    Email Address*
                                    </label>
                                    <TextBox
                                    type='email'
                                    id='email'
                                    name='email'
                                    placeholder='Eq. johndoe@yourcompany.com'
                                    txtBoxClass='regular-text'
                                    onChange={handleInputChange}
                                    value={txtValues.email}
                                    error={errors.email}
                                    required
                                    />
                                    {errors.email && (
                                    <div className='field-error'>{errors.email[0]}</div>
                                    )}
                                </div>
                                <div className='label-container'>
                                    <label className='' htmlFor='title'>
                                    Vulnerability Title*
                                    </label>
                                    <TextBox
                                    type='text'
                                    id='title'
                                    name='title'
                                    placeholder='Enter vulnerability title'
                                    txtBoxClass='regular-text'
                                    onChange={handleInputChange}
                                    value={txtValues.title}
                                    required
                                    error={errors.title}
                                    />
                                    {errors.title && (
                                    <div className='field-error'>{errors.title[0]}</div>
                                    )}
                                </div>

                                <div className='label-container'>
                                    <label className='' htmlFor='details'>
                                    Vulnerability Details*
                                    </label>
                                    <TextArea
                                    name='details'
                                    id='details'
                                    placeholder='Enter your vulnerability details here...'
                                    onChange={handleInputChange}
                                    value={txtValues.details}
                                    error={errors.details ? true : false}
                                    txtAreaClass='regular-text'
                                    required
                                    />
                                    {errors.details && (
                                    <div className='field-error'>{errors.details[0]}</div>
                                    )}
                                </div>

                                <div className='label-container'>
                                    <SelectBox
                                        dropdownList={securityLevel}
                                        label="Severity Level"
                                        searchTxt="Search Severity Level"
                                        id="securityLevel"
                                        name="securityLevel"
                                        selectClass="regular-text"
                                        headerStyle={{width:'100%'}}
                                        handleSelectBox={handleSelectChange}
                                        selectBoxValue={selectValues.securityLevel}
                                    />
                                    {errors.security_level && (
                                        <div className='field-error secondary-color'>{errors.security_level[0]}</div>
                                    )}
                                </div>

                                <div className='label-container'>
                                    <label className='' htmlFor='screenshot'>
                                    Screenshot
                                    </label>
                                    <FileInput
                                    id='screenshot'
                                    name='screenshot'
                                    txtBoxClass='regular-text'
                                    onChange={handleInputChange}
                                    value={txtValues.screenshot}
                                    required
                                    error={errors.screenshot}
                                    />
                                    {errors.screenshot && (
                                    <div className='field-error'>{errors.screenshot[0]}</div>
                                    )}
                                </div>

                                <div className='label-container'>
                                    <label className='' htmlFor='otherInfo'>
                                    Additional Information
                                    </label>
                                    <TextArea
                                        name='otherInfo'
                                        id='otherInfo'
                                        placeholder='Enter your vulnerability details here...'
                                        onChange={handleInputChange}
                                        value={txtValues.otherInfo}
                                        error={errors.otherInfo ? true : false}
                                        txtAreaClass='regular-text'
                                        required
                                    />
                                    {errors.other_info && (
                                    <div className='field-error'>{errors.other_info[0]}</div>
                                    )}
                                </div>

                                <div className='label-container'>
                                    <SelectBox
                                        dropdownList={remainAnonymous}
                                        label="Would you like to remain anonymous?"
                                        searchTxt="Yes or No"
                                        id="remainAnonymous"
                                        name="remainAnonymous"
                                        selectClass="regular-text"
                                        headerStyle={{width:'100%'}}
                                        handleSelectBox={handleSelectChange}
                                        selectBoxValue={selectValues.remainAnonymous}
                                    />
                                    {errors.remainAnonymous && (
                                        <div className='field-error secondary-color'>{errors.remainAnonymous[0]}</div>
                                    )}
                                </div>

                                <div className="checkbox-container">
                                    <CheckBox
                                        name="consent"
                                        value={txtValues.consent}
                                        onChange={handleInputChange}
                                        checkboxBgClass="with-border"
                                    />
                                    <label htmlFor="consent" className="small after-checkbox">I agree to report this vulnerability in a responsible manner, and I understand that disclosing the issue publicly before it is resolved could compromise the security of the system.</label>
                                </div>
                                <br/>
                                <button type='submit' className="full-width" style={{marginTop:'10px'}}>Send Request</button>
                                
                                <p className="justify privacy-policy">We hate spam and promise to keep your email address safe. Check out our <Link to="/privacy-policy/" className="secondary"><strong>Privacy Policy</strong></Link>.</p>
                            </form>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default ReportSecurityIssue