import React, { useState } from "react";

const FileInput = ({
    name,
    id,
    placeholder,
    divClass,
    divStyle,
    inputClass,
    inputStyle,
    onChange = () => {},
    theRef,
    value = "",
    error = false,
    required = false,
    hideLabelOnStart = false,
    disabled = false,
}) => {
    const [activeBorder, setActiveBorder] = useState(false);
    const [hideLabelStat, setHideLabelStat] = useState(hideLabelOnStart);

    const handleFocus = () => {
        setActiveBorder(true);
        setHideLabelStat(false);
    };

    const handleBlur = () => {
        setActiveBorder(false);
    };

    return (
        <div
            className={`field-container light-border ${
                error ? "error" : ""
            } ${divClass} ${activeBorder && "active"}`}
            style={divStyle}
        >
            <label
                className={`${value && "always-active"} ${
                    hideLabelStat && "invisible"
                }`}
                htmlFor={id}
            >
                {placeholder}
            </label>
            <input
                className={inputClass}
                style={inputStyle}
                type="file"
                name={name}
                id={id}
                placeholder={hideLabelStat ? placeholder : ""}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={onChange}
                ref={theRef}
                value={value}
                required={required}
                disabled={disabled}
                accept="image/*"
            />
        </div>
    );
};

export default FileInput;
